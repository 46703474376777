// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../../../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-flashcards-jsx": () => import("./../../../src/pages/flashcards.jsx" /* webpackChunkName: "component---src-pages-flashcards-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-cyber-sec-awareness-jsx": () => import("./../../../src/pages/projects/cyberSec-Awareness.jsx" /* webpackChunkName: "component---src-pages-projects-cyber-sec-awareness-jsx" */),
  "component---src-pages-projects-info-sec-policy-project-jsx": () => import("./../../../src/pages/projects/InfoSecPolicy-project.jsx" /* webpackChunkName: "component---src-pages-projects-info-sec-policy-project-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

